#artists {
	background: $white;
	color: $grey;
	display: flex;
	justify-content: start;
	flex-direction: column;

	header {
		ul.nav {
			border-bottom: $black;
			border-right: none; }
		@include susy-breakpoint($medium) {
			.brand {
				background: $white;
				border-bottom: 1px solid $grey;
				color: $grey; } } }


	h2 {
		@include susy-breakpoint($medium) {
			display: inline-block;
			font-size: 300%;

			&:hover {
				a {
					-webkit-text-stroke-width: 1px;
					-webkit-text-stroke-color: $grey;
					color: $white; }
				.image-preview {
					display: block; } } }

		a {
			display: block;
			position: relative;
			z-index: 2; } }

	.image-preview {
		display: none;
		width: 100%;
		// padding-bottom: 60%
		background-size: cover;
		background-position: center;
		pointer-events: none;
		@include susy-breakpoint($medium) {
			padding: 2rem;
			width: 100%;
			max-width: 1200px;
			position: fixed;
			// padding-bottom: 45%
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1; } }

	.artist-filter {
		padding: 1rem;
		display: flex;
		justify-content: center;

		.status {
			padding-left: 1rem;
			padding-right: 1rem;
			a {
				text-decoration: none;
				&.active {
					text-decoration: underline; } } } }
	.artist-row {
  		display: none;

  		&.show {
    		display: block; } }

	.disabled-link {
		pointer-events: none;
		cursor: default; } }
