#project {
	color: $grey;

	header {
		background: $lightgrey;
		color: $grey;
		.brand {
			background: $lightgrey;
			border-bottom: 1px solid $grey; } }

	h1 {
		text-align: center;
		width: 100%; }

	.project-intro {
		@include susy-breakpoint($medium) {
			display: flex;
			flex-direction: column;
			// height: calc(100vh - 80px)
			justify-content: space-between; }

		.project-slideshow {
			@include susy-breakpoint($medium) {
				position: fixed;
				top: 40px;
				right: 0;
				bottom: 0;
				width: 50%; }

			.slick-slide {
				height: 300px;
				background-position: center;
				background-size: cover;
				@include susy-breakpoint($small) {
					height: 500px; }
				@include susy-breakpoint($medium) {
					height: auto;
					padding-bottom: 0;
					height: 100vh; } } }
		.top {
			padding-top: 3rem;
			padding-bottom: 3rem;
			@include susy-breakpoint($medium) {
				padding-top: 9rem;
				padding-bottom: 9rem; } }
		.status {
			text-transform: capitalize;
			@include susy-breakpoint($medium);
			p {
				border: 1px solid $grey;
				display: inline-block;
				padding: 0.25rem 0.5rem; } }


		.bottom {
			margin-top: 2rem;
			@include susy-breakpoint($medium) {
				margin-top: 0; }
			p {
				&:first-child {
					margin-top: 0; }

				&:last-child {
					margin-bottom: 0; } } } }

	.project-content {
		p {
			margin: 1rem 0;
			text-indent: 2rem;

			&:first-child {
				margin-top: 0; }

			&:last-child {
				margin-bottom: 0; } } }

	.performace-history {
		ul.years {
			text-align: center;

			li {
				padding: 0.15rem 0.25rem;
				display: inline-block;
				margin: 0.25rem;
				border: 1px solid transparent;
				cursor: pointer;

				&.active {
					border: 1px solid; } } }

		.performances {
			position: relative;
			.year-group {
				position: absolute;
				top: 0;
				width: 100%;
				left: 0;
				opacity: 0;
				pointer-events: none;

				&.active {
					opacity: 1;
					pointer-events: all; } }

			.performance-row {
				display: flex;
				justify-content: space-between; } } }

	.media-quote {
		margin: 0.5rem 0; }

	.credits-wrap {
		display: flex;
		flex-wrap: wrap;
		.credit-block {
			margin-top: 1rem;
			flex-basis: 50%;
			width: 50%;
			&:nth-child(2n+1) {
				text-align: right; } } }

	.project-footer {
		position: relative;
		z-index: 1;
		.flex {
			display: flex;
			.related-projects {
				width: 100%; }
			.project {
				@include susy-breakpoint($medium) {
					width: 30%; } } }

		.artist {
			display: block;
			text-decoration: none;
			width: 100%;
			margin: 0 auto;
			@include susy-breakpoint($medium) {
				width: 50%; }
			.image {
				background: $lightgrey;
				padding-bottom: 68%;
				background-position: center;
				background-size: cover; }

			.title {
				text-align: center;
				height: 65px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 1rem;
				@include susy-breakpoint($medium) {
					height: 75px; }
				@include susy-breakpoint($xlarge) {
					height: 100px; }

				&:hover {
					background: $lightgrey; } } }

		.disabled-link {
			pointer-events: none;
			cursor: default; } }

	.video {
		padding-bottom: 3rem;
		@include susy-breakpoint($medium) {
			padding-bottom: 6rem; } }

	.supporters-list {
		@apply flex flex-wrap justify-center {}
		a.supporter {
			@apply p-4 {}
			flex-basis: 50%;
			text-decoration: none;
			@include susy-breakpoint($medium) {
				flex-basis: 33%; } } } }
