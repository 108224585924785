//home page css - create one for each page and include to keep shit organised.
#home {
	background: $grey; }

#subscribe-form {
	.form-row {
		display: flex;
		padding: 0.5rem 0;

		label {
			vertical-align: text-bottom;
			margin-right: 0.5rem; }

		input {
			flex-grow: 1;

			&.parsley-error {
				border-color: red; } }

		button {
			margin: 1rem auto;
			display: inline-block;
			padding: 0.5rem;
			border: 1px solid $grey;
			background: transparent;
			text-transform: uppercase;
			cursor: pointer; } }


	ul.parsley-errors-list {
		display: none; } }
