body.project {
	header {
		color: $grey; } }

body.artists {
	header ul.nav {
		background: $grey;
		border-color: $grey; }
	.brand {
		background: $black;
		color: $grey; } }

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	text-transform: uppercase;
	background: rgba($grey, 1);
	color: $white;
	z-index: 9;

	a {
		text-decoration: none; }

	@include susy-breakpoint($medium) {
		display: flex;
		flex-direction: row-reverse;

		.brand,
		ul.nav {
			flex-basis: 50%; } }

	.brand {
		border-bottom: 1px solid $white;
		background: $black;
		text-align: center;
		height: 28px;
		display: flex;
		align-items: center;
		letter-spacing: 1px;
		padding: 0 1rem;
		justify-content: center;
		@include susy-breakpoint($small) {
			height: 38px; }
		@include susy-breakpoint($medium) {
			height: 40px; } }

	ul.nav {
		border-bottom: 1px solid;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		text-align: center;
		padding: 0 1rem;
		justify-content: center;
		@include susy-breakpoint($small) {
			height: 32px; }
		@include susy-breakpoint($medium) {
			padding: 0 2rem;
			height: 40px;
			border-right: 1px solid; }

		li {
			width: 33.3333%; }

		li:first-child {
			text-align: left; }

		li:last-child {
			text-align: right; } } }
