#about {
	background: white;
	color: $grey;

	header {
		ul.nav {
			border-bottom: $black;
			border-right: none; }
		@include susy-breakpoint($medium) {
			.brand {
				background: $white;
				border-bottom: 1px solid $grey;
				color: $grey; } } } }

.staff-list-wrap {
	background: $lightgrey;
	padding: 2rem 0;
	@include susy-breakpoint($medium) {
		padding: 2rem 0 0; }

	h2 {
		margin-bottom: 2rem; } }

.staff-list {
	display: flex;
	flex-wrap: wrap;
	a.staff {
		flex-basis: 50%;
		text-decoration: none;

		h4 {
			text-transform: uppercase; }

		.title {
			text-transform: uppercase;
			height: 65px;
			display: flex;
			flex-direction: column;
			padding: 0 1rem;
			align-items: center;
			justify-content: center;
			@include susy-breakpoint($medium) {
				height: 75px; }
			@include susy-breakpoint($xlarge) {
				height: 100px; } }

		&:hover .title {
			background: $grey;
			color: $white; } } }


.profile-photo {
	max-width: 700px;
	margin: 2rem auto; }


.staff-bio-wrap {
	background: $lightgrey;

	.bio {
		p {
			margin: 1rem 0;
			text-indent: 2rem;

			&:first-child {
				margin-top: 0; }

			&:last-child {
				margin-bottom: 0; } } } }


.supporters-list-wrap {
	background: $black;
	padding: 2rem 0;
	@include susy-breakpoint($medium) {
		padding: 2rem 0 0; }

	h2 {
		margin-bottom: 2rem; }

	.supporters-list {
		@apply flex flex-wrap justify-center {}
		a.supporter {
			@apply p-4 {}
			flex-basis: 50%;
			text-decoration: none;
			@include susy-breakpoint($medium) {
				flex-basis: 25%; } }

		img {
			@apply filter invert {} } } }
