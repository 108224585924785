//typography
h1, h2, h3, h4, p {
	margin: 0;
	font-weight: normal; }

h1, h2, h3, h4 {
	a {
		text-decoration: none; } }

h1, h2 {
	font-size: 180%;
	text-transform: uppercase; }

h3 {
	font-size: 130%;
	text-transform: uppercase;
	@include susy-breakpoint($small) {
		font-size: 150%; } }


label {
	text-transform: uppercase; }

.text-huge {
	font-size: 300%; }

.text-large {
	font-size: 120%; }

.text-small {
	font-size: 85%; }

.text-smaller {
	font-size: 75%; }
