// body.expand-footer
// 	footer
// 		bottom: 0
// 		position: relative

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: $lightergrey;
	padding: 0 1rem;
	height: 50px;
	display: flex;
	align-items: center;
	font-size: 90%;
	z-index: 1;
	transition: all 0.3s ease;
	z-index: 9;
	border-top: 1px solid $grey;
	@include susy-breakpoint($medium) {
		height: 40px;
		padding: 0 2rem; }

	a {
		text-decoration: none; }

	.footer-wrap {
		width: 100%;
		display: flex;
		text-align: center;
		justify-content: space-between;
		flex-direction: column;
		@include susy-breakpoint($medium) {
			flex-direction: row; } }

	ul.social {
		padding-bottom: 0.5rem;
		@include susy-breakpoint($medium) {
			padding-bottom: 0; }
		li {
			display: inline-block;
			padding: 0 0.5rem 0 0; } } }
