.container {
	@include container(100%);
	&.c600 {
		@include container(600px); }
	&.c800 {
		@include container(900px); }
	&.c1200 {
		@include container(1200px); }
	&.c1400 {
		@include container(1400px); } }

.bg-white {
	background: $white; }

.bg-grey {
	background: $grey;
	color: $white; }

.align-right {
	text-align: right; }

.text-stroke {
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: $grey; }

.text-stroke-white {
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: $white; }

.text-center {
	text-align: center; }

.text-white {
	color: $grey; }

.text-black {
	color: $grey; }

.text-clear {
	color: transparent; }

.text-uppercase {
	text-transform: uppercase; }

.p1 {
	padding: 1rem; }

.p2 {
	padding: 2rem 1rem;
	@include susy-breakpoint($medium) {
		padding: 2rem; } }

.py1 {
	padding-top: 1rem;
	padding-bottom: 1rem; }

.py2 {
	padding-top: 2rem;
	padding-bottom: 2rem; }

.px1 {
	padding-left: 1rem;
	padding-right: 1rem; }

.px2 {
	padding-left: 1rem;
	padding-right: 1rem;
	@include susy-breakpoint($medium) {
		padding-left: 2rem;
		padding-right: 2rem; } }

.m05 {
	margin: 0.5rem; }

.m1 {
	margin: 1rem; }

.m2 {
	margin: 2rem 1rem;
	@include susy-breakpoint($medium) {
		margin: 2rem; } }

.my05 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem; }

.my1 {
	margin-top: 1rem;
	margin-bottom: 1rem; }

.my2 {
	margin-top: 2rem;
	margin-bottom: 2rem; }

.mx05 {
	margin-left: 0.5rem;
	margin-right: 0.5rem; }

.mx1 {
	margin-left: 1rem;
	margin-right: 1rem; }

.mx2 {
	margin-left: 1rem;
	margin-right: 1rem;
	@include susy-breakpoint($medium) {
		margin-left: 2rem;
		margin-right: 2rem; } }

.leading-normal {
	line-height: 1.15; }

.leading-loose {
	line-height: 1.3; }

.flex {
	@include susy-breakpoint($medium) {
		display: flex; }

	.col {
		flex-basis: 100%;
		@include susy-breakpoint($medium) {
			flex-basis: 50%; } } }
