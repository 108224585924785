#projects {
	background: $grey;
	padding-top: 130px;
	@include susy-breakpoint($small) {
		padding-top: 190px; }
	@include susy-breakpoint($medium) {
		padding-top: 160px; }

	header {
		background: transparent; }

	ul.nav {
		background: $grey;
		border-right: none; }

	.brand {
		background: $lightgrey;
		border-bottom: 1px solid $grey;
		// +susy-breakpoint($medium)
		// 	background: transparent
		color: $grey; }

	.right {
		display: none;
		@include susy-breakpoint($medium) {
			display: block;
			background: $lightgrey;
			position: fixed;
			width: 50%;
			height: 100%;
			top: 0;
			right: 0;
			transition: background-color 0.3s ease; }

		.preview-logo {
			position: absolute;
			width: 16rem;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			animation-play-state: paused;

			&.loading {
				animation: pulse 1s infinite;
				animation-play-state: running; } }

		@keyframes pulse {
			0% {
				opacity: 0; }
			50% {
				opacity: 1; }
			100% {
				opacity: 0; } }

		.project-preview {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			opacity: 0;
			transition: opacity 0.3s ease;
			background-position: center;
			background-size: cover;

			&.show {
				opacity: 1;
				transition: opacity 0.3s ease; }

			h2 {
				position: absolute;
				bottom: -3rem;
				width: 100%;
				text-align: center;
				color: $black; } } }

	.project-filter {
		position: fixed;
		width: 100%;
		background: $grey;
		border-bottom: 1px solid;
		padding: 1rem 0;
		top: 50px;
		height: 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 1;
		@include susy-breakpoint($small) {
			height: 120px;
			top: 70px; }
		@include susy-breakpoint($medium) {
			width: 50%;
			top: 40px;
			height: 120px; }

		a {
			text-decoration: none; }

		ul {
			padding: 0.25rem 0;
			text-align: center; }

		li {
			display: inline-block;
			margin: 0.25rem 0.35rem;
			@include susy-breakpoint($medium) {
				margin: 0.25rem 0.55rem; }

			a.active {
				text-decoration: underline; } }

		li.category a {
			.category-circle {
				margin-right: 0.2rem;
				@include susy-breakpoint($medium) {
					margin-right: 0.5rem; }
				&::after {
					width: 8px;
					height: 8px;
					position: absolute;
					content: "";
					background: $grey;
					left: 1px;
					top: 1px;
					border-radius: 50%;
					transition: all 0.3s ease;
					@include susy-breakpoint($medium) {
						width: 12px;
						height: 12px; } } }

			&.active {
				.category-circle::after {
					opacity: 0; } } } }

	.project-list {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		@include susy-breakpoint($medium) {
			padding-left: 2rem;
			padding-right: 2rem;
			padding-bottom: 2rem; }

		.project {
			// margin-bottom: 1rem
			padding-top: 1rem;
			padding-bottom: 1rem;
			transition: all 0.3s ease;
			border-bottom: 1px solid;

			a {
				text-decoration: none;

				h3 {
					max-width: 100%;
					@include susy-breakpoint($medium) {
						max-width: none; } }
				.organiser {
					text-transform: capitalize;
					p {
						text-transform: capitalize; } } }

			&.hide {
				display: none; } }

		.project-row {
			display: flex;
			justify-content: space-between; } } }
