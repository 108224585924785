.barba-container {
	min-height: 100vh;
	padding-top: 50px;
	padding-bottom: 50px;
	@include susy-breakpoint($small) {
		padding-top: 70px;
		padding-bottom: 50px; }
	@include susy-breakpoint($medium) {
		padding-top: 40px;
		padding-bottom: 40px; }

	.flex {
		@include susy-breakpoint($medium) {
			display: flex;

			.left,
			.right {
				width: 50%;
				flex-basis: 50%; } } } }

ul {
	padding: 0;
	margin: 0;
	list-style-type: none; }

input[type="text"] {
	border: none;
	border-bottom: 1px solid;
	background: none;
	border-radius: 0; }

.category-circle {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: relative;
	@include susy-breakpoint($medium) {
		width: 14px;
		height: 14px; } }

hr {
	border: none;
	height: 12px;
	position: relative;
	@include susy-breakpoint($medium) {
		height: 16px; }

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: -2px;
		margin-left: -5px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		border: 1px solid $grey;
		position: relative;
		@include susy-breakpoint($medium) {
			top: -2px;
			width: 14px;
			height: 14px;
			margin-left: -7px; } } }


.projects-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	a.project {
		text-align: center;
		text-decoration: none; }

	&.full-width {
		a.project {
			flex-basis: 50%;
			width: 50%;
			@include susy-breakpoint($medium) {
				flex-basis: 33.3333%;
				width: 33.3333%; }
			@include susy-breakpoint($xlarge) {
				flex-basis: 25%;
				width: 25%; } } }

	&.half-width {
		a.project {
			width: 100%;
			&:not(:first-child) {
				display: none; }
			@include susy-breakpoint($medium) {
				&:not(:first-child) {
					display: block; }
				width: 50%; } } }

	.image {
		background: $lightgrey;
		padding-bottom: 68%;
		background-position: center;
		background-size: cover; }

	.title {
		text-transform: uppercase;
		height: 65px;
		display: flex;
		padding: 0 1rem;
		align-items: center;
		justify-content: center;
		@include susy-breakpoint($medium) {
			height: 75px; }
		@include susy-breakpoint($xlarge) {
			height: 100px; } }

	a.project:hover .title {
		background: $lightgrey;
		color: $grey; } }

.video {
	z-index: 9;
	text-align: center;
	padding: 1rem 2rem;
	@include susy-breakpoint($medium) {
		padding: 4rem 2rem; }

	.video-wrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } } }


.git-box {
	display: flex;
	flex-direction: row; }
.git-label {
	width: 60px;
	@include susy-breakpoint($medium) {
		width: 100px; } }
.git-arrow {
	padding: 0 1rem; }

html.no-scroll {
	@apply overflow-y-hidden {} }

#acknowledgement {
	@apply fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-xl flex justify-center items-center transition duration-1000 cursor-pointer opacity-0 pointer-events-none {}
	z-index: 99;
	backface-visibility: hidden;
	perspective: 1000;
	transform: translateZ(0);
	backdrop-filter: blur(24px);

	&.active {
		@apply opacity-100 pointer-events-auto {} }

	.acknowledgement-inner {
		@apply flex flex-1 h-full flex-col justify-around items-center transform translate-y-0 transition duration-1000 ease-in-out opacity-100 p-8 text-center {}
		will-change: transform;
		line-height: 1.3em;
		max-width: 1200px;
		font-size: 120%;
		@screen md {
			font-size: 200%; } }

	&.hiding,
	&.hidden {
		@apply delay-500 opacity-0 {}
		.acknowledgement-inner {
			@apply translate-y-12 opacity-0 {} } }

	&.hidden {
		@apply pointer-events-none {} } }
