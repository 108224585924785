.error-container {
	height: 80vh;
	display: table;

	.error-message {
		display: table-cell;
		vertical-align: middle;
		width: 100vw;
		text-align: center;
		font-family: Arial, sans-serif;
		font-smoothing: subpixel-antialiased;
		-webkit-font-smoothing: subpixel-antialiased;

		h1 {
			font-weight: normal; }

		p {
			font-weight: 300; } } }
