//base html stuff
html,
body {
	background: $grey;
	height: 100%;
	min-height: 100%;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	color: $white;
	font-family: 'Surt', sans-serif;
	font-size: 90%;

	@include susy-breakpoint($small) {
		font-size: 100%; }

	@include susy-breakpoint($xlarge) {
		font-size: 110%; } }

a {
	text-decoration: underline;
	color: inherit; }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

.material-icons {
	line-height: inherit; }

img {
	display: block;
	width: 100%; }

.clearfix:after {
	content: "";
	display: table;
	clear: both; }

#preload-bar {
	width: 100%;
	height: 1px;
	position: fixed;
	top: 50px;
	left: 0;
	z-index: 300;
	background: none;
	margin-top: -1px;

	@include susy-breakpoint($small) {
		top: 70px; }

	@include susy-breakpoint($medium) {
		top: 40px; }

	svg {
		height: 1px; }

	path {
		stroke: $white; } }

#preload-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 200;
	background-color: $grey; }
